//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-208:_2628,_6920,_7432,_4758,_1840,_9036,_7108,_7016;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-208')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-208', "_2628,_6920,_7432,_4758,_1840,_9036,_7108,_7016");
        }
      }catch (ex) {
        console.error(ex);
      }